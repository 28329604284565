import React from 'react'
import Carousel from 'react-multi-carousel'
import { Link } from 'gatsby'
import "react-multi-carousel/lib/styles.css";
import { FaChevronRight } from 'react-icons/fa'
import { Box, Text, Button, Flex } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

const ImageGridItem = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
`;

const TextItem = styled(Box)`
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: rgba(74, 74, 74, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
    h3 {
       font-size: 2rem;
       font-weight: 600;
       line-height: 2rem;
       margin-bottom: .8rem;
    }
    .member-title {
        font-family: 'Open Sans', sans-serif;
        color: #eab117;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
    }
`
export const ImageItem = (props) => (
    <TextItem>
        <Text as="h3" fontSize={{ sm: '1.75rem', md: '2rem', lg: '2.25rem'}}>
            {props.memberName}
        </Text>
        <Text className="member-title">
            {props.memberTitle}
        </Text>
        {/* <div style={{
            borderBottom: '2px solid white',
            width: '10rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1rem',
            marginBottom: '1rem',

        }}/> */}
        {props.airBnbLink ? (
            <Link to={props.airBnbLink}>
            <Button variant="ghost"
                color="pine.200"
                textTransform="uppercase"
                fontSize="1.25rem"
                _hover={{
                    backgroundColor: 'none',
                    color: 'white'
                }}>
                {props.airBnbText}
            </Button>
            </Link>
        ) : <Text color="pine.200"
        textTransform="uppercase"
        fontSize="1.25rem"
        fontWeight="600">Coming Soon!</Text>}

        {props.bookingLink ? (
            <Link to='/booking'>
            <Button variant="ghost"
                color="pine.200"
                textTransform="uppercase"
                fontSize="1.25rem"
                _hover={{
                    backgroundColor: 'none',
                    color: 'white'
                }}>
                {props.bookingText}
            </Button>
            </Link>
        ) : <Text color="pine.200"
        textTransform="uppercase"
        fontSize="1.25rem"
        fontWeight="600">Coming Soon!</Text>}

        {props.vrboLink ? (
            <Link to='/vrbo'>
            <Button variant="ghost"
                color="pine.200"
                textTransform="uppercase"
                fontSize="1.25rem"
                _hover={{
                    backgroundColor: 'none',
                    color: 'white'
                }}>
                {props.vrboText}
            </Button>
            </Link>
        ) : <Text color="pine.200"
        textTransform="uppercase"
        fontSize="1.25rem"
        fontWeight="600">Coming Soon!</Text>}

        {props.directLink ? (
            <a href={props.directLink} target="_blank" rel="noopener noreferrer">
            <Button variant="ghost"
                color="pine.200"
                textTransform="uppercase"
                fontSize="1.25rem"
                _hover={{
                    backgroundColor: 'none',
                    color: 'white'
                }}>
                {props.directText}
            </Button>
            </a>
        ) : <Text color="pine.200"
        textTransform="uppercase"
        fontSize="1.25rem"
        fontWeight="600">Coming Soon!</Text>}
        

    </TextItem>
)

const Locations = (props) => {

    return (
        <Flex flexWrap="wrap" id="book-our-properties">
        <Box w={{ sm: '100%', md: '50%' }}>
            <ImageGridItem w="100%" h="100%">
          <StaticImage
                    className="friend" 
                    src='../../../images/victor.png'
                    alt="Victor, Idaho properties"
                    placeholder="blurred"
                    layout="fullWidth"
                />
                
                <ImageItem 
            memberName={<>Victor, ID</>}
            airBnbText={<>Book on AirBnb{' '}<FaChevronRight /></>}
            airBnbLink="/airbnb"
            bookingLink="/booking"
            bookingText={<>Book on booking.com{' '}<FaChevronRight /></>}
            vrboText={<>Book on VRBO{' '}<FaChevronRight /></>}
            vrboLink="/vrbo"
            directText={<>Book Directly &amp; Save 10%{' '}<FaChevronRight /></>}
            directLink="https://newwestproperties.guestybookings.com/listings?city=Victor"
            
        />
            </ImageGridItem>
        </Box>
        <Box
             w={{ sm: '100%', md: '50%' }}
        >
            <ImageGridItem w="100%" h="100%">
                
          <StaticImage
                    className="friend" 
                    src='../../../images/santa-rosa.png'
                    alt="Santa Rosa, CA properties"
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <div style={{backgroundColor: "rgba(74, 74, 74, 0.5)"}} />
                <ImageItem 
                    memberName={<>Santa Rosa, CA</>}
                    airBnbText={<>Book on AirBnb{' '}<FaChevronRight /></>}
                    airBnbLink="/airbnb"
                    bookingLink="/booking"
                    bookingText={<>Book on booking.com{' '}<FaChevronRight /></>}
                    vrboText={<>Book on VRBO{' '}<FaChevronRight /></>}
                    vrboLink="/vrbo"
                    directText={<>Book Directly &amp; Save 10%{' '}<FaChevronRight /></>}
                    directLink="https://newwestproperties.guestybookings.com/listings?city=Santa%20Rosa"

            
        />
            </ImageGridItem>
        </Box>
        <Box
          w={{ sm: '100%', md: '50%' }}   
        >
            <ImageGridItem w="100%" h="100%">
          <StaticImage
                    className="friend" 
                    src='../../../images/kanab.png'
                    alt="Kanab, Utah properties"
                    placeholder="blurred"
                    layout="fullWidth"
                />
                
                <ImageItem 
                    memberName={<>Kanab, UT</>}
                    airBnbText={<>Book on AirBnb{' '}<FaChevronRight /></>}
                    airBnbLink="/airbnb"
                    bookingLink="/booking"
                    bookingText={<>Book on booking.com{' '}<FaChevronRight /></>}
                    vrboText={<>Book on VRBO{' '}<FaChevronRight /></>}
                    vrboLink="/vrbo"
                    directText={<>Book Directly &amp; Save 10%{' '}<FaChevronRight /></>}
                    directLink="https://newwestproperties.guestybookings.com/listings?city=Kanab"
                
        />
            </ImageGridItem>
            
        </Box>
        <Box
             w={{ sm: '100%', md: '50%' }}
        >
            <ImageGridItem w="100%" h="100%">
          <StaticImage
                    className="friend" 
                    src='../../../images/twin-falls.png'
                    alt="Twin Falls, ID properties"
                    placeholder="blurred"
                    layout="fullWidth"
                />
                
                <ImageItem 
                directText={<>Book Directly &amp; Save 10%{' '}<FaChevronRight /></>}
                memberName={<>Twin Falls, ID</>}
                airBnbText={<>Book on AirBnb{' '}<FaChevronRight /></>}
                airBnbLink="/airbnb"
                bookingLink="/booking"
                bookingText={<>Book on booking.com{' '}<FaChevronRight /></>}
                vrboText={<>Book on VRBO{' '}<FaChevronRight /></>}
                vrboLink="/vrbo"
                directLink="https://newwestproperties.guestybookings.com/listings?city=Twin%20Falls"
            
        />
            </ImageGridItem>
        </Box>
    </Flex>
    )
}

export default Locations