import React from 'react'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import { Box, Text, Container } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const QuoteBox = styled.div`
  text-align: center;
  padding: 4rem;
  background-color: ${props => props.bg};
`;

const HomeCarousel = (props) => {

    return (
        <>
        <Carousel
            swipeable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            //containerClass="carousel-container"
            //removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
        <QuoteBox bg="#3ca596">
          <Container maxW="container.sm">
        <Text color="#24886c" fontSize="1.25rem" textTransform="uppercase" fontWeight="600" mb="4">
          What our guests are saying
        </Text>
        <Text color="white" fontSize="1.5rem">
        "Allen’s place was very clean, nicely furnished and equipped. He responded quickly to our request to check in one day earlier and it was an easy reservation change. Victor is a nice, quaint town with a few good restaurants and grocery store."
        </Text>
        </Container>
        </QuoteBox>
        <QuoteBox bg="#3ca596">
          <Container maxW="container.sm">
        <Text color="#24886c" fontSize="1.25rem" textTransform="uppercase" fontWeight="600" mb="4">
          What our guests are saying
        </Text>
        <Text color="white" fontSize="1.5rem">
        "We loved the historic Santa Rosa neighborhood. Allen was the perfect host, left us a sweet note and complimentary beverages. The cottage was nicely appointed, with all the necessities and many thoughtful touches."
        </Text>
        </Container>
        </QuoteBox>
        <QuoteBox bg="#3ca596">
          <Container maxW="container.sm">
        <Text color="#24886c" fontSize="1.25rem" textTransform="uppercase" fontWeight="600" mb="4">
          What our guests are saying
        </Text>
        <Text color="white" fontSize="1.5rem">
        {<>"Allen’s place is a real gem. It is in a great location to easily access all that Teton valley has to offer. We stayed here for a month as we worked from home and explored the area.<br />We loved our time here!"</>}
        </Text>
        </Container>
        </QuoteBox>
        <QuoteBox bg="#3ca596">
          <Container maxW="container.sm">
        <Text color="#24886c" fontSize="1.25rem" textTransform="uppercase" fontWeight="600" mb="4">
          What our guests are saying
        </Text>
        <Text color="white" fontSize="1.5rem">
        "What an adorable and cozy cottage! Allen has created a lovely experience for his guests, from the welcoming and cozy home, relaxing backyard, fluffy bathroom towels and comfy beds! We really enjoyed our experience and plan on returning!"
        </Text>
        </Container>
        </QuoteBox>
        </Carousel>
        </>
    )
}

export default HomeCarousel