import React from 'react'
import { Box,
        Text,
        Heading,
        Container,
        Flex,
        } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const WelcomeHomeContent = () => (
    <>
    <Box id="green-building" my="0">
    <Container maxW="container.md" py={28} centerContent>
        <Heading as="h1"
              fontSize={{ sm: '3rem', md: '4rem' }}
              lineHeight={{ sm: '3rem', md: '4rem'}} 
              my={12}
              textAlign="center"
              color="pine.200"
              textTransform="uppercase">
                  Welcome Home
            </Heading>
        
    <Text color="white"
        fontSize="1.5rem"
        textAlign="center"
        mb={12}
        maxW="container.md"
        >
    Welcome to New West Properties. We’re in love with the deserts, mountains and coast of the Western US, and we’ve dedicated our company to allowing others to develop the same love for these landscape and the people that live in them.</Text>
    <Text color="white"
        fontSize="1.5rem"
        textAlign="center"
        mb={12}
        maxW="container.md"
        >
Whether you’re travelling in the desert of Utah, Wine Country of California, or the majestic Teton Mountains, we’ve got the perfect basecamp for your adventures. We’re committed to making every element of your stay memorable, convenient and comfortable. 
    </Text>

    <Container maxW="container.sm"
        my={20}>
            <Flex justifyContent="space-around" flexWrap="wrap" alignItems="center">
            <Box mb={6}>
                <Text
                    textAlign="center"
                    color="white"
                    fontWeight="600"
                    fontSize="1.5rem"
                >
                ALLEN &amp; LEA THOMAS<br />
Founders, New West Properties
                </Text>
            </Box>
            <Box mb={6} w="200px" style={{
                    border: '4px solid white'
                }}>
            <StaticImage
                
                    src="../../../images/allen-leah.jpg"
                    alt="Allen &amp; Lea Thomas"
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </Box>
            </Flex>
    </Container>
            </Container>
    
    </Box>
    </>
)

export default WelcomeHomeContent