import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'


const BackgroundSection = ({ className, children }) => {

    const { bearBackground } = useStaticQuery(
    graphql`
  {
   bearBackground: file(relativePath: {eq: "bear-bg.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 6000
          quality: 80
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
  )

  const image = getImage(bearBackground);
  const bgImage = convertToBgImage(image);

  return (
    <div style={{width: '100%', height: '100vh'}}>
    <BackgroundImage
        tag="section"
        className={className}
        {...bgImage}
        //backgroundColor="green.400"
    >
        {children}
    </BackgroundImage>
    </div>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
    width: 100%;
    min-height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export default StyledBackgroundSection