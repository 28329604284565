import React from "react"

import { Box, Flex, IconButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import Helmet from "react-helmet"

import { StaticImage } from "gatsby-plugin-image"
import BackgroundSlider from "react-background-slider"

import { motion } from 'framer-motion'

import Seo from "../components/seo"
import HomeBackground from "../components/HomePage/HomeBackround"
import MonumentValleySectionBackground from "../components/HomePage/WelcomeHome/MonumentValleySectionBackround"
import HomepageContent from "../components/HomePage/WelcomeHome/WelcomeHomeContent"
import HomeCarousel from "../components/HomePage/HomeCarousel"
import Locations from "../components/HomePage/Locations/Locations"

import BearImg from "../images/bear-bg.jpg"
import SpudImg from "../images/Spud.jpg"
import YellowstoneImg from "../images/yellowstone.jpg"
import ZionImg from "../images/zion.jpg"
import NapaImg from "../images/napa.jpg"
import TwinImg from "../images/twinfalls.jpg"


const MotionBox = motion(Box)

function ThemeToggle() {
  const { toggleColorMode: toggleMode } = useColorMode()
  const ToggleIcon = useColorModeValue(SunIcon, MoonIcon)

  return (
    <IconButton
      icon={<ToggleIcon />}
      variant="ghost"
      aria-label="Toggle Theme"
      onClick={toggleMode}
    />
  )
}

const IndexPage = (props) => {

  //console.log(props)
  return (
  <>
  <Seo title="New West Properties" />
  <Helmet defer={false}>
    <script src="https://s3.us-west-2.amazonaws.com/inlightmedia.io/guesty/guesty.js" type="text/javascript" />
    {/* <script src={withPrefix('searchBar.js')} type="text/javascript" /> */}
  </Helmet>
      {/* <HomeBackground /> */}
      <Box h="100vh">
        <BackgroundSlider 
          images={[
            BearImg,
            SpudImg,
            YellowstoneImg,
            ZionImg,
            NapaImg,
            TwinImg
          ]}
          duration={6}
          transition={2}
        />
      </Box>

      <Flex flexDirection="column" 
          className="flex-me"
          justifyContent="center"
          position="absolute"
          top="0"
          height="100%"
          w="100%">
      <Box>

        
        {/* <MotionBox
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 0.75,
          }}
        > */}
             
               <Box bg="rgba(32, 32, 32, 0.5)"
                paddingTop="3"
                paddingBottom="2"
                w="100%" position="absolute" bottom="5%">
                  <Flex justifyContent="center">
                  <Box id="search-widget_IO312PWQ" ></Box>
                  </Flex>
               
               </Box>
                {/* </MotionBox> */}
        </Box>
        </Flex> 
        
      <Box w="100%">
        
        {/* Toggle light/dark themes
        <ThemeToggle /> */}
        <MonumentValleySectionBackground>
        
        <HomepageContent />
        </MonumentValleySectionBackground>
      </Box>
      <Box>
        <Locations />
      </Box>
      {/* <Box>
        <PropertiesCarousel />
      </Box> */}
      <Box w="100%">
        <HomeCarousel />
      </Box>
      <Box w="100%">
      <StaticImage
                src="../images/tetons-background.jpg"
                alt="Teton Mountains"
                placeholder="blurred"
                layout="fullWidth"
            />
      </Box>
  </>
  )
}


export default IndexPage
