import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'


const MonumentValleySectionBackground = ({ className, children }) => {

    const { desertBackground } = useStaticQuery(
    graphql`
  {
   desertBackground: file(relativePath: {eq: "monument-valley-bg.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 6000
          quality: 80
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
  )

  const image = getImage(desertBackground);
  const bgImage = convertToBgImage(image);

  return (
    <div style={{width: '100%', height: '100%'}}>
    <BackgroundImage
        tag="section"
        className={className}
        {...bgImage}
    >
        {children}
    </BackgroundImage>
    </div>
  )
}

const StyledBackgroundSection = styled(MonumentValleySectionBackground)`
    width: 100%;
    min-height: 100%;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    /* background: rgba(32, 32, 32, 0.4);
    opacity: 1 !important;
    background-blend-mode: multiply; */
`;

export default StyledBackgroundSection